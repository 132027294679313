html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
progress {
  vertical-align: baseline;
}
template,
[hidden] {
  display: none;
}
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:active,
a:hover {
  outline-width: 0;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: inherit;
}
b,
strong {
  font-weight: bolder;
}
dfn {
  font-style: italic;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background-color: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
figure {
  margin: 1em 40px;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
button,
input,
select,
textarea {
  font: inherit;
  margin: 0;
}
optgroup {
  font-weight: bold;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
@font-face {
  font-family: vertex;
  src: url("63b4dcf1af1b9fff52fef7b12b802cb6.ttf");
}
* {
  font-size: 12px;
  color: #fff;
}
.title-xs {
  font-family: vertex;
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
  color: #fff;
  margin-bottom: 30px;
}
.title-l {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  margin-top: 32px;
}
.title-sm {
  font-size: 20px;
  line-height: 21px;
  color: #fff;
}
.text {
  font-size: 15px;
  line-height: 24px;
  text-align: justify;
  color: #fff;
  opacity: 0.8;
}
.text-info {
  font-size: 15px;
  line-height: 20px;
  color: #fff;
}
.logo {
  font-family: vertex;
}
.text-logo {
  font-family: vertex;
  font-size: 16px;
  opacity: 0.8;
}
.introduction-section-1 {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.introduction-section-1 .title-logo {
  position: absolute;
  bottom: 112px;
  font-size: 31px;
  color: #fff;
}
.introduction-section-1 .title-letter {
  position: absolute;
  bottom: 160px;
  margin-top: auto;
  font-size: 39px;
  line-height: 36px;
  font-weight: 600;
  color: #fff;
}
.introduction-section-1 .explore {
  position: absolute;
  bottom: 70px;
  font-size: 14px;
  color: #fff;
  animation: breath 2s infinite;
}
.introduction-section-1 .arrow {
  position: absolute;
  bottom: 70px;
  left: 134px;
  width: 20px;
  animation: arrow 2s infinite;
}
.introduction-section-1 .video {
  position: absolute;
  width: 100%;
  margin-left: -25px;
}
.introduction-section-1 .video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.introduction-section-1 .video .mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@-moz-keyframes breath {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.2;
  }
}
@-webkit-keyframes breath {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.2;
  }
}
@-o-keyframes breath {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.2;
  }
}
@keyframes breath {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.2;
  }
}
@-moz-keyframes arrow {
  0% {
    opacity: 0;
    transform: translateY(-1px);
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    transform: translateY(3px);
  }
}
@-webkit-keyframes arrow {
  0% {
    opacity: 0;
    transform: translateY(-1px);
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    transform: translateY(3px);
  }
}
@-o-keyframes arrow {
  0% {
    opacity: 0;
    transform: translateY(-1px);
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    transform: translateY(3px);
  }
}
@keyframes arrow {
  0% {
    opacity: 0;
    transform: translateY(-1px);
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    transform: translateY(3px);
  }
}
.introduction-section-2 {
  margin-top: 86px;
}
.introduction-section-2 .description-1 {
  margin-top: 30px;
}
.introduction-section-2 .photo {
  margin-top: 18px;
  width: 100%;
  border-radius: 4px;
}
.introduction-section-2 .names {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}
.introduction-section-2 .names .name {
  width: 25%;
  font-size: 14px;
  opacity: 0.8;
}
.introduction-section-2 .description-2 {
  margin-top: 36px;
}
.innovate {
  margin-top: 100px;
  position: relative;
}
.innovate .title-xs {
  margin-bottom: 40px;
}
.innovate .panel {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 12px;
  border-radius: 4px;
  overflow: hidden;
}
.innovate .panel .logo {
  display: block;
  width: 100px;
  height: auto;
}
.innovate .panel .pattern {
  position: absolute;
  width: 200px;
  opacity: 0.2;
  right: -60px;
  top: -30px;
}
.innovate .panel .info .title {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 4px;
}
.innovate .panel .info .description {
  margin-top: 7px;
  line-height: 20px;
}
.innovate .panel .info .more {
  font-size: 14px;
  opacity: 0.4;
  margin-top: 4px;
}
.innovate .video {
  position: absolute;
  width: 100%;
  right: -25px;
  top: 400px;
}
.innovate .video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.innovate .video .mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.business {
  position: relative;
  margin-top: 100px;
}
.business .title-xs {
  margin-bottom: 60px;
}
.business .panel {
  position: relative;
  margin-top: 32px;
  padding: 28px 24px 26px;
  border-radius: 6px;
  box-shadow: 0 2px 13px rgba(0,0,0,0.5);
}
.business .panel .header {
  display: flex;
  align-items: center;
}
.business .panel .header .icon {
  position: absolute;
  width: 120px;
  right: 0;
  top: -50px;
}
.business .panel .header .panel-title {
  margin-left: 0;
}
.business .panel .description {
  margin-top: 16px;
}
.business .panel .text {
  font-size: 14px;
  line-height: 22px;
}
.business .video {
  position: absolute;
  top: 490px;
  width: 100vw;
  margin-left: -25px;
}
.business .video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.business .video .mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.cases {
  position: relative;
  margin-top: 200px;
}
.cases .list .case {
  margin-top: 40px;
  width: 100%;
}
.cases .list .case .cover {
  width: 100%;
  position: relative;
}
.cases .list .case .cover .bg {
  width: 100%;
  height: auto;
  border-radius: 6px;
}
.cases .list .case .cover .play {
  position: absolute;
  width: 88px;
  left: 10px;
  bottom: 16px;
}
.cases .list .case .info .title {
  margin-top: 12px;
  font-size: 20px;
  line-height: 24px;
}
.cases .list .case .info .tags {
  margin-top: 12px;
  display: flex;
}
.cases .list .case .info .tags .tag {
  opacity: 0.7;
  margin-right: 8px;
  padding: 8px 8px;
  border-radius: 2px;
  border: 1px solid rgba(255,255,255,0.6);
  font-size: 12px;
  line-height: 11px;
}
.cases .message {
  margin-top: 80px;
  font-size: 20px;
  line-height: 29px;
  color: #fff;
}
.client {
  margin-top: 100px;
}
.client .logowall {
  width: 100%;
}
.client .list {
  margin: 40px -6px 0 -6px;
  display: flex;
  flex-wrap: wrap;
}
.client .list .client-logo-wrapper {
  width: 33.3%;
  padding: 0 6px;
}
.client .list .client-logo-wrapper .client-logo {
  width: 100%;
  display: block;
}
.partner {
  margin-top: 120px;
  position: relative;
}
.partner .list {
  margin-top: 50px;
}
.partner .list .row {
  margin-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(255,255,255,0.2);
}
.partner .list .row:last-child {
  border-bottom: none;
}
.partner .list .row .header .logo {
  height: 40px;
  margin-bottom: 12px;
}
.partner .list .row .header .title {
  font-size: 16px;
}
.footer {
  margin-top: 100px;
}
.footer .title-xs {
  margin-bottom: 40px;
}
.footer .contact .card {
  border-radius: 4px;
  margin-top: 12px;
  padding: 26px;
  background-image: url("https://zhinan-vertex.oss-cn-shanghai.aliyuncs.com/immersion/card-bg.png");
  background-size: cover;
}
.footer .contact .card .header {
  display: flex;
}
.footer .contact .card .header .name {
  flex: 1;
  font-size: 20px;
  line-height: 30px;
}
.footer .contact .card .header .brand {
  font-size: 16px;
}
.footer .contact .card .info {
  margin-top: 20px;
}
.footer .contact .card .info .email {
  margin-top: 6px;
}
.footer .tail {
  position: relative;
}
.footer .tail .video {
  position: absolute;
  width: 100vw;
  left: -25px;
  top: 0;
}
.footer .tail video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.footer .tail .mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.footer .tail .logo {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  margin-top: 45%;
}
* {
  box-sizing: border-box;
}
html,
body {
  width: 100%;
  height: 100%;
}
body {
  padding: 0 25px;
  background-color: #06021b;
}
img {
  height: auto;
}
.video-mask {
  z-index: 10;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transform: translateY(150vh);
  background-color: #000;
}
.video-mask.show {
  animation: video-show 1s ease 0s 1 normal forwards;
}
.video-mask.fold {
  animation: video-fold 1s ease 0s 1 normal forwards;
}
.video-mask .video-wrapper {
  width: 100%;
  margin-top: 90px;
  height: calc(100% - 90px);
}
.video-mask .video-wrapper video {
  width: 100%;
  height: 100%;
}
.video-mask .close {
  position: absolute;
  right: 10px;
  top: 55px;
  width: 30px;
  color: #fff;
}
.video-mask .close img {
  width: 100%;
  height: auto;
}
a {
  text-decoration: none;
}
@-moz-keyframes video-show {
  from {
    transform: translateY(150vh);
  }
  to {
    transform: translateY(0vh);
  }
}
@-webkit-keyframes video-show {
  from {
    transform: translateY(150vh);
  }
  to {
    transform: translateY(0vh);
  }
}
@-o-keyframes video-show {
  from {
    transform: translateY(150vh);
  }
  to {
    transform: translateY(0vh);
  }
}
@keyframes video-show {
  from {
    transform: translateY(150vh);
  }
  to {
    transform: translateY(0vh);
  }
}
@-moz-keyframes video-fold {
  from {
    transform: translateY(0vh);
  }
  to {
    transform: translateY(150vh);
  }
}
@-webkit-keyframes video-fold {
  from {
    transform: translateY(0vh);
  }
  to {
    transform: translateY(150vh);
  }
}
@-o-keyframes video-fold {
  from {
    transform: translateY(0vh);
  }
  to {
    transform: translateY(150vh);
  }
}
@keyframes video-fold {
  from {
    transform: translateY(0vh);
  }
  to {
    transform: translateY(150vh);
  }
}
